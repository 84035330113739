<template>
  <div class="page flex-col">
    <div class="group_1 flex-col">
      <div class="box_1 flex-col"></div>
      <span class="text_1">商家简介</span>
      <span class="text_2">
        护肤在现代已经成为一门学问，人们受皮肤问题困扰不再盲目地去商店买各种化妆品来试，而是由皮肤科医生开出处方，提供针对性的护肤建议。随着人们对健康的追求日益高涨和皮肤医学的高速发展，带有更高安全性和有效性的医学护肤品已经成为不可阻挡的一股潮流。
      </span>
      <div class="box_2 flex-row">
        <span class="text_3">菜品</span>
        <span class="text_4">商家</span>
        <div class="group_2 flex-col"></div>
      </div>
    </div>
    <div class="group_3 flex-col">
  
      <div class="box_4 flex-row justify-between">
        <img
          class="label_2"
          referrerpolicy="no-referrer"
          src="./assets/img/2e1bdf61e7bf4a4ab1db95975a51044b.png"
        />
        <span class="text_9">门店信息</span>
      </div>
      <div class="box_5 flex-col">
        <div class="box_6 flex-row justify-between">
          <div class="box_7 flex-col"></div>
          <div class="box_8 flex-col">
            <span class="text_10">渡爽餐饮（大智路店）</span>
            <div class="image-wrapper_1 flex-row justify-between">
              <img
                class="label_3"
                referrerpolicy="no-referrer"
                src="./assets/img/35b48fe79f9ec6e2461b53b44078ae6e.png"
              />
              <img
                class="label_4"
                referrerpolicy="no-referrer"
                src="./assets/img/0f8ef65d58631541036381b472748597.png"
              />
              <img
                class="label_5"
                referrerpolicy="no-referrer"
                src="./assets/img/abb2969072bd7d202eb2d08e68c72d49.png"
              />
              <img
                class="label_6"
                referrerpolicy="no-referrer"
                src="./assets/img/f1f7f585cb73e98e8621b6cdb777b0c5.png"
              />
              <img
                class="label_7"
                referrerpolicy="no-referrer"
                src="./assets/img/956cd2e8e7304ce8d96ab2d553652b33.png"
              />
            </div>
            <div class="box_9 flex-row justify-between">
              <div class="text-wrapper_2 flex-col"><span class="text_11">营业中</span></div>
              <span class="text_12">8:00~19:00</span>
            </div>
          </div>
        </div>
        <span class="text_13">地址：武汉市洪山区XXXXXXXXX1号商铺</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {}
    };
  },
  methods: {}
};
</script>
<style scoped lang="css" src="./assets/index.rem.css" />